@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/values";

.workshopLocationDetail {
  display: flex;
  margin-bottom: values.$space4;
}
  
.icon {
  color: values.$colorNeutral800;
  margin-right: values.$space4;
}

.content {
  font-size: values.$px_16;
  font-weight: 500;
}