@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/values';
@use '$styles/mediaqueries';

.component {
  padding: values.$space8;
  background: values.$colorWhite;
  border-radius: values.$radius8;
  border: 1px solid values.$colorNeutral200;
}

.locationMap {
  width: 100%;
  height: 100%;
}

.googleMap {
  height: 100%;
}

.locationDetails {
  width: 100%;
  font-weight: 500;
  line-height: values.$px_20;
}

.cta {
  margin-bottom: values.$space6;
  width: 100%;
  @include mediaqueries.mediumUp {
    width: unset;
  }
}